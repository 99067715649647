import React from 'react';
//import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}

        <div>
          <a href="https://pure-renaissance.castinc.co.jp/" target="_blank" rel="noreferrer">
            <button type="button" className="w-96 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-4xl px-5 py-2.5 text-center mr-2 mb-2">
              Rsys
            </button>
          </a>
        </div>

        <div className="mt-8">
          <a href="https://pure-renaissance-engine.castinc.co.jp/engine/" target="_blank" rel="noreferrer">
            <button type="button" className="w-96 text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-4xl px-5 py-2.5 text-center mr-2 mb-2">
              決済エンジン
            </button>
          </a>
        </div>

        <div className="mt-8">
          <a href="https://osys.castinc.co.jp/" target="_blank" rel="noreferrer">
            <button type="button" className="w-96 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-4xl px-5 py-2.5 text-center mr-2 mb-2 ">
              Osys 従来版
            </button>
          </a>
        </div>

        <div className="mt-8">
          <a href="https://osys.castinc.jp/" target="_blank" rel="noreferrer">
            <button type="button" className="w-96 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-4xl px-5 py-2.5 text-center mr-2 mb-2">
              Osys AMEX対応版
            </button>
          </a>
        </div>

      </header>
    </div>
  );
}

export default App;
